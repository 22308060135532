// import Image from "next/image";
import TeeTimeGlobe from "../assets/icons/globe.svg";
import TeeTime from "../assets/images/logoTeeTime1.svg";

export const TeeTimeGlobeIcon = ({ style }) => (
  <img
    src={TeeTimeGlobe}
    alt="Tee Time Globe"
    style={{
      // width: '3%', // Valor predeterminado
      // height: 'auto',
      // marginRight: '6px',
      ...style, // Sobrescribir estilos si se pasan
    }}
  />
);

export const TeeTimeIcon = ({ style }) => <img src={TeeTime} alt="Tee Time" />;

export function ArrowUpImage() {
  return (
    <svg
      // className={className}
      width="46"
      height="51"
      viewBox="0 0 46 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 47L23 4.58139"
        stroke="#3586B4"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M42 25.791L23 4.58171"
        stroke="#3586B4"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M4 27.1162L23 4.80226"
        stroke="#3586B4"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  );
}

// export const TeeTimeGlobeIcon: React.FC<IconProps> = ({ style }) => (
//   <Image
//     src={TeeTimeGlobe}
//     alt='Globe'
//     style={{
//       // width: '3%', // Valor predeterminado
//       // height: 'auto',
//       // marginRight: '6px',
//       ...style // Sobrescribir estilos si se pasan
//     }}
//   />
// )

export const WhatsAppIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2017_3811)">
        <path
          d="M21.9535 0.820312C9.93349 0.820312 0.186035 10.5678 0.186035 22.5878C0.186035 34.6077 9.93349 44.3552 21.9535 44.3552C33.9735 44.3552 43.7209 34.6077 43.7209 22.5878C43.7209 10.5678 33.9735 0.820312 21.9535 0.820312ZM22.4148 35.2614C22.4145 35.2614 22.4152 35.2614 22.4148 35.2614H22.4095C20.229 35.2604 18.0863 34.7137 16.1835 33.6761L9.27717 35.4873L11.1256 28.7381C9.9853 26.7631 9.38545 24.5225 9.38645 22.227C9.38944 15.0461 15.2339 9.20398 22.4148 9.20398C25.8997 9.20531 29.171 10.5618 31.6305 13.0233C34.0904 15.4852 35.4442 18.7575 35.4429 22.2373C35.4399 29.4186 29.5948 35.2614 22.4148 35.2614Z"
          fill="white"
        />
        <path
          d="M22.4192 11.4043C16.4462 11.4043 11.5886 16.2599 11.5859 22.2286C11.5853 24.2739 12.1579 26.2658 13.2417 27.9893L13.4991 28.3988L12.405 32.3935L16.5033 31.3187L16.8989 31.5532C18.5613 32.5397 20.4672 33.0615 22.4102 33.0621H22.4145C28.3828 33.0621 33.2404 28.2062 33.2431 22.2372C33.2441 19.3446 32.1188 16.6249 30.0744 14.5789C28.0301 12.5329 25.3112 11.4053 22.4192 11.4043ZM28.7884 26.8826C28.517 27.6425 27.2167 28.3364 26.5912 28.43C26.0302 28.5137 25.3208 28.5486 24.5409 28.3012C24.0679 28.151 23.4618 27.9508 22.6849 27.6156C19.4196 26.206 17.2869 22.9191 17.1241 22.7019C16.9614 22.4846 15.7949 20.9372 15.7949 19.3352C15.7949 17.7336 16.6359 16.9461 16.9341 16.6206C17.2327 16.2948 17.5855 16.2134 17.8024 16.2134C18.0193 16.2134 18.2365 16.2154 18.4261 16.2247C18.6261 16.2347 18.8945 16.1487 19.1585 16.7834C19.4299 17.435 20.0809 19.0367 20.1622 19.1994C20.2436 19.3625 20.2978 19.5525 20.1895 19.7697C20.0809 19.9869 19.7202 20.4556 19.3757 20.8827C19.2312 21.0617 19.0429 21.2212 19.2329 21.547C19.4226 21.8725 20.0762 22.9384 21.0438 23.8013C22.2873 24.91 23.3362 25.2534 23.6617 25.4165C23.9869 25.5792 24.1769 25.552 24.3669 25.3351C24.5565 25.1179 25.1806 24.3848 25.3975 24.059C25.6144 23.7332 25.8316 23.7877 26.1299 23.8963C26.4285 24.0045 28.0288 24.7921 28.3543 24.9548C28.6798 25.1179 28.8967 25.1993 28.978 25.3351C29.0597 25.471 29.0597 26.1223 28.7884 26.8826Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2017_3811">
          <rect
            width="43.5349"
            height="43.5349"
            fill="white"
            transform="translate(0.186035 0.820312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FacebookIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="36"
      viewBox="0 0 20 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78338 12.0346H0.186035V17.8392H5.78338V35.2532H14.1794V17.8392H19.273L19.7767 12.0346H14.1794V9.61696C14.1794 8.22965 14.4481 7.68111 15.741 7.68111H19.7767V0.425293H13.0487C8.01672 0.425293 5.78338 2.72394 5.78338 7.12386V12.0346Z"
        fill="white"
      />
    </svg>
  );
};

export const InstagramIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.372 0.425293C13.6412 0.425293 13.052 0.445609 11.1916 0.532679C4.86454 0.822912 1.35272 4.33182 1.06249 10.6589C0.978324 12.5193 0.958008 13.1114 0.958008 17.8392C0.958008 22.57 0.978324 23.1621 1.06249 25.0196C1.35272 31.3438 4.86454 34.8585 11.1916 35.1487C13.052 35.2329 13.6412 35.2532 18.372 35.2532C23.1028 35.2532 23.6948 35.2329 25.5552 35.1487C31.8707 34.8585 35.397 31.3496 35.6814 25.0196C35.7656 23.1621 35.7859 22.57 35.7859 17.8392C35.7859 13.1114 35.7656 12.5193 35.6814 10.6589C35.397 4.34053 31.8823 0.820009 25.5552 0.532679C23.6948 0.445609 23.1028 0.425293 18.372 0.425293ZM18.3719 3.56558C23.0214 3.56558 23.5728 3.58299 25.41 3.66716C30.1321 3.88193 32.332 6.11963 32.5497 10.804C32.631 12.6412 32.6484 13.1897 32.6484 17.8392C32.6484 22.4887 32.631 23.0402 32.5497 24.8745C32.332 29.5559 30.135 31.7994 25.41 32.0142C23.5728 32.0954 23.0243 32.1158 18.3719 32.1158C13.7223 32.1158 13.1709 32.0983 11.3366 32.0142C6.60584 31.7965 4.41458 29.5501 4.19691 24.8745C4.11564 23.0402 4.09532 22.4887 4.09532 17.8392C4.09532 13.1897 4.11564 12.6383 4.19691 10.804C4.41168 6.11672 6.61164 3.87903 11.3366 3.66426C13.1709 3.58009 13.7223 3.56558 18.3719 3.56558ZM9.43001 17.8385C9.43001 12.8988 13.4352 8.89646 18.3721 8.89646C23.3089 8.89646 27.3141 12.9017 27.3141 17.8385C27.3141 22.7783 23.3089 26.7806 18.3721 26.7806C13.4352 26.7806 9.43001 22.7783 9.43001 17.8385ZM18.3716 23.6434C15.1646 23.6434 12.567 21.0459 12.567 17.8388C12.567 14.6346 15.1646 12.0341 18.3716 12.0341C21.5758 12.0341 24.1792 14.6317 24.1792 17.8388C24.1792 21.0459 21.5758 23.6434 18.3716 23.6434ZM25.5754 8.54541C25.5754 7.39028 26.5129 6.45573 27.6651 6.45573C28.8231 6.45573 29.7577 7.39028 29.7577 8.54541C29.7577 9.70053 28.8202 10.6351 27.6651 10.6351C26.51 10.6351 25.5754 9.69763 25.5754 8.54541Z"
        fill="white"
      />
    </svg>
  );
};

export const TwitterIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3243 15.155L32.3599 0.425293H29.6032L19.1057 13.1326L10.7456 0.425293H0.967285L13.7021 19.7313L0.967285 35.2532H3.71402L14.9307 21.6494L23.8001 35.2532H33.6185L20.3342 15.155H20.3243ZM4.72281 2.81248H9.41723L29.6032 33.137H25.1185L4.72281 2.81248Z"
        fill="white"
      />
    </svg>
  );
};
